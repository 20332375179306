<template>
    <div class="login-page" @wheel.stop="">
        <img class="background-image" src="../assets/backgroud.png" alt="Background Image"/>
        <img class="cleanLogo" src="../assets/cleanLogo.png" alt="cleanLogo image"/>
        <img style="position: absolute;top:11.5%;z-index: 0;width: 371px;height: 134px;" src="../assets/layers1.png">
        <img style="position: absolute;top:28%;z-index: 0;width: 371px;height: 134px;" src="../assets/layers2.png">
        <img style="position: absolute;top:4%;z-index: 1;width: 300px;height: 323px;" src="../assets/sred2.png">
        <img style="position: absolute;top:39.5%;z-index: 1;width: 320px;height: 100px;" src="../assets/check-out30.png">
        <img style="position: absolute;top:56%;z-index: 1;width: 320px;height: 100px;" src="../assets/day-item30.png">
        <img style="position: absolute;top:72.5%;z-index: 1;width: 320px;height: 100px;" src="../assets/deep30.png">
        <div class="login-form" style="position:absolute;top:88%;">
            <!--            <van-button type="primary" @click="login" color="linear-gradient(to right, #ff6034, #ee0a24)">-->
            <!--                渐变色按钮-->
            <!--            </van-button>-->
            <div style="font-size: 11px;color: white;">红包已存入"拾舍清洁APP-我的-优惠券"</div>
            <van-button style="margin-top: 1%;" type="primary" size="large" block @click="openApp" class="custom-button"></van-button>
        </div>
    </div>
</template>

<style scoped>
    body {
        overflow: hidden;
    }

    .login-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden; /* 隐藏滚动条 */
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .cleanLogo {
        position: absolute;
        top: 2%;
        width: 263px;
        height: 63px;
    }

    .login-form {
        width: 80%;
        max-width: 300px;
        margin-bottom: 20vh;
    }

    .van-checkbox__label {
        color: white !important; /* 将文字颜色设置为白色 */
    }

    .custom-button {
        margin-top: 14px;
        background-image: url('../assets/sBtnBackGround.png');
        background-size: cover;
        border-radius: 69px;
        /* 其他自定义样式 */
    }
</style>

<script>
    // import {ref} from 'vue';
    import {Button} from 'vant';
    export default {
        components: {
            [Button.name]: Button
        },
        setup() {
            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        name: "successPage",
        created() {
        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.title = '领取成功';
        },
        data() {
            return {
            }
        },
        methods: {
            isWechatBrowser(){
                return /micromessenger/i.test(navigator.userAgent);
            },
            openApp() {
                if (this.isWechatBrowser()) {
                    // 在微信内置浏览器中
                    this.$router.push('day-item-browser');
                } else {
                    // 不在微信内置浏览器中，直接跳转到 App Store 的 URL
                   window.location.href="https://apps.apple.com/aus/app/%E6%8B%BE%E8%88%8D%E6%B8%85%E6%B4%81/id6447120079/";
                //window.location.replace('itms-appss://apps.apple.com/aus/app/%E6%8B%BE%E8%88%8D%E6%B8%85%E6%B4%81/id6447120079/');
                }
            }
        }
    };
</script>
