<template>
    <div class="login-page" @wheel.stop=""  >
        <van-button id="testBtn"  type="primary" text="测试" size="large"  @click="test" class="custom-button"></van-button>
    </div>
</template>
<!--:style="{ backgroundColor: '#E7F3FF' }"-->
<style scoped>
    body {
        /*overflow: hidden;*/
        /*background-color: #E7F3FF;*/
    }

    .login-page {
        /*height: 100vh;*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        /*overflow: hidden; !* 隐藏滚动条 *!*/
    }
    

</style>

<script>
    // import {ref} from 'vue';
    import {Button} from 'vant';

    // const userAgent = navigator.mediaDevices.getUserMedia();
    export default {
        components: {
            [Button.name]: Button
        },
        setup() {
            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        name: "loginPage",
        created() {
//             // 阻止页面下拉滚动
//             document.addEventListener('touchmove', function (event) {
//                 event.preventDefault();
//             }, { passive: false });
//
// // 阻止页面触摸滚动
//             document.addEventListener('touchstart', function (event) {
//                 if (event.touches.length > 1) {
//                     event.preventDefault();
//                 }
//             });
         window.mobileOCjs={
             openNewWeb:this.openNewWeb
         }
        },
        data() {
            return {

            }
        },
        methods: {
            // handleKeyboardHide() {
            //     alert(1111);
            //
            //     // 键盘收起时的处理逻辑
            //     // 您可以还原页面样式，例如恢复 `.login-page` 的样式
            //     document.querySelector('.login-page').style.height="100%";
            //
            // },
            openNewWeb(url){
                window.webkit.messageHandlers.jsMethod.postMessage(url);
            },
            test(){
                alert("已点击按钮")
                window.mobileOCjs.openNewWeb('2dsfdsf');
            }
        }
    };
</script>
