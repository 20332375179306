<template>
    <h3 style="text-align: center;padding-left: 3%;padding-right: 3%;">WEBSITE PRIVACY POLICY</h3>
    <div class="paragraph" style="padding-left: 3%;padding-right: 3%;">
        <p>This Privacy Policy applies to all personal information collected by Ten House Pty Ltd via the website
            located at www.tenhousecleaning.com.</p>
        <h4>1. What is "personal information"?</h4>
        <span>a) The Privacy Act 1988 (Cth) currently defines "personal information" as meaning information or an opinion about an identified individual or an individual who is reasonably
identifiable:
            <br/>i. whether the information or opinion is true or not: and
            <br/>
            ii. whether the information or opinion is recorded in a material form or not.
        </span>
        <p>b) If the information does not disclose your identity or enable your identity to be ascertained, it will in most cases not be classified as "personal information" and
            will not be subject to this privacy policy.</p>
        <h4>2. What information do we collect?</h4>
        <p>The kind of personal information that we collect from you will depend on how you use the website. The personal information which we collect and hold about you may include: phone number email address name address payment details.</p>
        <h4>3. How we collect your personal information</h4>
        <p>a) We may collect personal information from you whenever you input such information into the website.</p>
        <p>b) We also collect cookies from your computer which enable us to tell when you use the website and also to help customise your website experience. As a general rule, however, it is not possible to identify you personally from our use of cookies.</p>
        <h4>4. Purpose of collection</h4>
        <p>a) The purpose for which we collect personal information is to provide you with the best service experience possible on the website.</p>
        <p>b) We customarily disclose personal information only to our service providers who assist us in operating the website. Your personal information may also be exposed from time to time to maintenance and support personnel acting in the normal course of their duties.</p>
        <p>c) By using our website, you consent to the receipt of direct marketing material. We will only use your personal information for this purpose if we have collected such information direct from you, and if it is material of a type which you would reasonably expect to receive from us. We do not use sensitive personal information in direct marketing activity. Our direct marketing material will include a simple means by which you can request not to receive further communications of this nature.</p>
        <h4>5. Access and correction</h4>
        <p>Australian Privacy Principle 12 permits you to obtain access to the personal information we hold about you in certain circumstances, and Australian Privacy Principle 13 allows you to correct inaccurate personal information subject to certain exceptions. If you would like to obtain such access, please contact us as set out below.</p>
        <h4>6. Complaint procedure</h4>
        <p>If you have a complaint concerning the manner in which we maintain the privacy of your person information, please contact us as set out below. All complaints will be considered by Colin Huang and we may seek further information from you to clarify your concerns. If we agree that your complaint is well founded, we will, in consultation with you, take appropriate steps to rectify the problem. If you remain dissatisfied with the outcome, you may refer the matter to the Office of the Australian Information Commissioner.</p>
        <h4>7. Overseas transfer</h4>
        <p>Your personal information will not be disclosed to recipients outside Australia unless you expressly request us to do so. If you request us to transfer your personal information to an overseas recipient, the overseas recipient will not be required to comply with the Australian Privacy Principles and we will not be liable for any mishandling of your information in such circumstances.</p>
        <h4>8. How to contact us about privacy</h4>
        <p>If you have any queries, or if you seek access to your personal information, or if you have a complaint about our privacy practices, you can contact us through:</p>
        <p>tenhousecleaning@gmail.com.</p>
    </div>
</template>
<style>
    .paragraph {
        text-align: justify; /* 设置段落对齐方式为两端对齐 */
        text-align-last: left; /* 保持最后一行左对齐 */
        text-justify: inter-word; /* 设置单词间隔自动调整，以实现更好的排版效果 */
    }
</style>
<script>
    export default {
        name:'termsPage',
        data() {
            return {
            };
        },
        mounted() {
            document.title = '用户隐私政策';
        },
    };
</script>
