<template>
    <div class="invite-new-page" @wheel.stop="">
        <img src="../../assets/invite_new_bg.png" class="invite-bg">
        <div class="invite-new-form">
            <van-field  v-model="phoneNo" label-width="10%" label="+61" placeholder="请输入手机号" style="border-radius: 30px;width: 95%;margin: 0 auto;"/>
            <van-field
                    v-model="verifyCode"
                    placeholder="请输入验证码"
                    style="border-radius: 30px;width: 95%;margin: 5.5% auto;margin-bottom: 0;"
            >
                <template #button>
                    <label @click="getCode" style="color: black;">{{ timeText }}</label>
                </template>
            </van-field>
            <!--            <router-link to="/Terms">跳转到关于页面</router-link>-->
            <van-checkbox v-model="checked" icon-size="14px" checked-color="#1650bf" style="margin:3.3% 0% 3.3% 3.3%;">
                <span style="color: white;font-size: 9px;">
                    注册代表您已同意<a @click="onClickTerms">《用户协议》</a>、<a @click="onClickPrivacy">《用户隐私政策》</a>
                </span>


            </van-checkbox>
            <!--            <van-button type="primary" @click="login" color="linear-gradient(to right, #ff6034, #ee0a24)">-->
            <!--                渐变色按钮-->
            <!--            </van-button>-->

            <van-button @click="login"  class="invite-login-button"></van-button>
        </div>

    </div>
</template>
<style>
    .invite-new-page {
        position: relative; /* 设置父容器为相对定位 */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .invite-bg {
        /*z-index: -1;*/
        /*max-width: 100%;*/
        /*height: 100%;*/
        /*object-fit: cover; !* 根据容器大小调整图片裁剪方式 *!*/
        max-width: 100%;
        height: auto;
    }
    .invite-new-form {
        position: absolute; /* 绝对定位，可以放置在任意位置 */
        top: 70%; /* 从顶部的位置，可以根据需要调整 */
        width: 88%;
    }
    .invite-login-button{
        width: 100%;
        background-image: url('../../assets/invite_new_receive.png');
        background-size: cover;
        background-size: cover;
        background-position: center;
        padding: 8%; /* 按钮内边距，可以根据需要调整 */
        border: none; /* 移除按钮边框 */
        border-radius: 100px; /* 圆角按钮 */
        cursor: pointer; /* 鼠标指针样式 */
    }
</style>
<script>
    import {Field, CellGroup, Button, CheckboxGroup, Checkbox,showToast} from 'vant';
    import axios from "axios";

    const urlParams = new URLSearchParams(window.location.search);
    const oldPhone = urlParams.get('oldPhone');
    const regexPhone = /^\d{9,10}$/ ;
    const regexCode = /^\d{6}$/;
    // const inviteCode = urlParams.get('inviteCode');
    export default {
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [CellGroup.name]: CellGroup,
            [CheckboxGroup.name]: CheckboxGroup,
            [Checkbox.name]: Checkbox
        },
        data(){
            return {
                phoneNo: '',
                verifyCode: '',
                timeText: '获取验证码',
                checked:false
            }
        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.title = '收到邀请';
        },
        methods:{
            login() {
                const isPhoneMatch = regexPhone.test(this.phoneNo);
                const isCodeMatch = regexCode.test(this.verifyCode);
                if(!isPhoneMatch){
                    showToast("电话号码格式错误！");
                    return;
                }
                if(!isCodeMatch){
                    showToast("验证码格式错误！");
                    return;
                }
                if(this.phoneNo==oldPhone){
                    showToast("邀请人不能是自己！");
                    return;
                }
                if(!this.checked){
                    showToast("请同意服务条款！");
                    return;
                }
                // axios.post('http://47.108.193.146:8090/login/userLoginH5', {
                axios.post('https://api.tenhousecleaning.com//login/userLoginH5', {
                    // JSON 数据
                    phoneNo: this.phoneNo,
                    verifyCode: this.verifyCode,
                    oldPhone: oldPhone
                }, {
                    headers: {
                        'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                    },
                })
                    .then(response => {
                        // 处理响应数据
                        if(response.data.retCode=="0000"){
                            this.$router.push('invite-success');
                        }else{
                            showToast(response.data.retMsg);
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                    });
            },
            getCode(){
                if(this.timeText=="获取验证码"){
                    // let idfv =""; //urlParams.get('idfv');
                    // const phone = "+61"+this.phoneNo;
                    const isPhoneMatch = regexPhone.test(this.phoneNo);
                    if(!isPhoneMatch){
                        showToast("电话号码格式错误！");
                        return;
                    }
                    this.timeText=60;
                    this.startTimer();
                    axios.post('https://api.tenhousecleaning.com/login/sendMsgH5', {
                        // JSON 数据
                        phoneNo: this.phoneNo
                    }, {
                        headers: {
                            'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                        },
                    })
                        .then(response => {
                            // 处理响应数据
                            if(response.data.retCode=="0000"){
                                showToast("发送成功！");
                            }else{
                                showToast(response.data.retMsg);
                            }
                        })
                        .catch(error => {
                            // 处理错误
                            console.error(error);
                        });
                }

            },
            startTimer() {
                setInterval(() => {
                    if (this.timeText > 0) {
                        this.timeText--;
                    }else{
                        this.timeText='获取验证码';
                    }
                }, 1000);
            },
            onClickTerms() {
                this.$router.push('terms');
            },
            onClickPrivacy() {
                this.$router.push('privacy');
            },
        }
    }

</script>