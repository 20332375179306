// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Invite from '@/components/Invite.vue';
import Terms from '@/components/Terms.vue';
import Privacy from '@/components/Privacy.vue';
import InviteSuccess from '@/components/invite/InviteSuccess';
import DayItemOld from '@/components/DayItemOld.vue';
import DayItemNew from '@/components/DayItemNew.vue';
import DayItemSuccess from '@/components/DayItemSuccess.vue';
import DayBrowserTip from '@/components/DayBrowserTip.vue';
import EmpExam from "@/components/exam/EmpExam";
import RatingRules from "@/components/rating/RatingRules"
import Test from "@/components/test/Test";
import InviteApp from "@/components/invite/InviteApp";
import InviteNew from "@/components/invite/InviteNew"
const routes = [
    { path: '/clean-h5/invite', component: Invite },
    { path: '/clean-h5/terms', component: Terms },
    { path: '/clean-h5/privacy', component: Privacy },
    { path: '/clean-h5/invite-success', component: InviteSuccess },
    { path: '/clean-h5/day-item-old', component: DayItemOld },
    { path: '/clean-h5/day-item-new', component: DayItemNew },
    { path: '/clean-h5/day-item-success', component: DayItemSuccess },
    { path: '/clean-h5/day-item-browser', component: DayBrowserTip },
    { path: '/clean-h5/emp-exam', component: EmpExam },
    { path: '/clean-h5/rating-rules', component: RatingRules },
    { path: '/clean-h5/test', component: Test },
    { path: '/clean-h5/invite-app', component: InviteApp },
    { path: '/clean-h5/invite-new', component: InviteNew },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    document.documentElement.style.overflow = 'auto';
    next();
});
export default router;
