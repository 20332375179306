<template>
    <div class="invite-success" @wheel.stop="">
        <img src="../../assets/invite_success_bg.png" class="invite-success-bg">
        <van-button id="receiveBtn" @click="openApp"
                    class="invite-success-button"></van-button>
    </div>
</template>

<style>
    .invite-success {
        position: relative; /* 设置父容器为相对定位 */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .invite-success-bg{
        /*max-width: 100%;*/
        /*height: 100%;*/
        /*object-fit: cover; !* 根据容器大小调整图片裁剪方式 *!*/
        /*width: 750px;*/
        /*height: 1738px;*/
        max-width: 100%;
        height: auto;
    }
    .invite-success-button{
        width: 87%;
        position: absolute; /* 绝对定位，可以放置在任意位置 */
        top: 87.5%; /* 从顶部的位置，可以根据需要调整 */
        left: 6%; /* 从左侧的位置，可以根据需要调整 */
        /*transform: translate(-50%, -50%); !* 水平和垂直居中 *!*/
        /*background-color: #0070c0; !* 按钮背景颜色 *!*/
        /*color: #fff; !* 按钮文本颜色 *!*/
        background-image: url("../../assets/invite_success_ex.png");
        background-size: cover;
        background-position: center;
        padding: 7%; /* 按钮内边距，可以根据需要调整 */
        border: none; /* 移除按钮边框 */
        border-radius: 100px; /* 圆角按钮 */
        cursor: pointer; /* 鼠标指针样式 */
    }
</style>

<script>
    import {Button} from 'vant';
    export default {
        components: {
            [Button.name]: Button
        },
        setup() {
            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        created() {
        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.title = '领取成功';
        },
        data() {
            return {
            }
        },
        methods: {
            openApp() {
                window.location.href="https://apps.apple.com/aus/app/拾舍清洁/id6447120079";
            }
        }
    };
</script>
