import { createApp } from 'vue'
import App from './App.vue'
import { Toast } from 'vant';
import 'vant/lib/index.css';
import 'vant/es/toast/style';
import router from './router';
import VueScrollTo from 'vue-scrollto';



createApp(App).use(Toast).use(router).use(VueScrollTo).mount('#app')
