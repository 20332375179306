<template>
    <div class="login-page" @wheel.stop="">
        <img class="background-image" src="../assets/backgroud.png" alt="Background Image"/>
        <img class="cleanLogo" src="../assets/cleanLogo.png" alt="cleanLogo image"/>
        <img style="position: absolute;top:14.5%;z-index: 0;width: 371px;height: 134px;" src="../assets/layer1.png">
        <img style="position: absolute;top:31%;z-index: 0;width: 371px;height: 134px;" src="../assets/layer2.png">
        <img style="position: absolute;top:18%;z-index: 1;width: 254px;height: 317px;" src="../assets/red.png">
        <div class="login-form" style="position:absolute;top:70%;">
            <van-field v-model="phoneNo" label-width="10%" label="+61" placeholder="请输入手机号" style="border-radius: 30px;"/>
            <van-field
                    v-model="verifyCode"
                    placeholder="请输入验证码"
                    style="border-radius: 30px;margin-top: 15px;"
            >
                <template #button>
                    <label @click="getCode" style="color: black;">{{ timeText }}</label>
                </template>
            </van-field>
            <!--            <router-link to="/Terms">跳转到关于页面</router-link>-->
            <van-checkbox v-model="checked" icon-size="14px" checked-color="#1650bf">
                <span style="color: white;font-size: 9px;">
                    注册代表您已同意<a @click="onClickTerms">《用户协议》</a>、<a @click="onClickPrivacy">《用户隐私政策》</a>
                </span>


            </van-checkbox>
            <!--            <van-button type="primary" @click="login" color="linear-gradient(to right, #ff6034, #ee0a24)">-->
            <!--                渐变色按钮-->
            <!--            </van-button>-->

            <van-button type="primary" size="large" block @click="login" class="custom-button"></van-button>
        </div>
    </div>
</template>

<style scoped>
    body {
        /*overflow: hidden;*/
    }

    .login-page {
        /*height: 100vh;*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        /*overflow: hidden; !* 隐藏滚动条 *!*/
    }

    .background-image {
        /*position: absolute;*/
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .cleanLogo {
        position: absolute;
        top: 5%;
        width: 263px;
        height: 63px;
    }

    .login-form {
        width: 80%;
        max-width: 300px;
        margin-bottom: 20vh;
    }

    .van-checkbox__label {
        color: white !important; /* 将文字颜色设置为白色 */
    }

    .custom-button {
        background-image: url('../assets/btnBackgroud.png');
        background-size: cover;
        border-radius: 69px;
        /* 其他自定义样式 */
    }
</style>

<script>
    // import {ref} from 'vue';
    import {Field, CellGroup, Button, CheckboxGroup, Checkbox,showToast} from 'vant';
    import axios from 'axios';
    // import { useRoute } from 'vue-router';
    const urlParams = new URLSearchParams(window.location.search);
    const oldPhone = urlParams.get('oldPhone');
    const regexPhone = /^\d{9,10}$/ ;
    const regexCode = /^\d{6}$/;
    const inviteCode = urlParams.get('inviteCode');

    // const userAgent = navigator.mediaDevices.getUserMedia();
    export default {
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [CellGroup.name]: CellGroup,
            [CheckboxGroup.name]: CheckboxGroup,
            [Checkbox.name]: Checkbox
        },
        setup() {
            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        name: "loginPage",
        created() {
//             // 阻止页面下拉滚动
//             document.addEventListener('touchmove', function (event) {
//                 event.preventDefault();
//             }, { passive: false });
//
// // 阻止页面触摸滚动
//             document.addEventListener('touchstart', function (event) {
//                 if (event.touches.length > 1) {
//                     event.preventDefault();
//                 }
//             });

        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.title = '推荐有奖';
            // 监听软键盘收起事件
            // document.addEventListener('focusout', this.handleKeyboardHide);
        },
        beforeUnmount() {
            // 移除事件监听
            // document.removeEventListener('focusout', this.handleKeyboardHide);
        },
        data() {
            return {
                phoneNo: '',
                verifyCode: '',
                timeText: '获取验证码',
                checked:false
            }
        },
        methods: {
            // handleKeyboardHide() {
            //     alert(1111);
            //
            //     // 键盘收起时的处理逻辑
            //     // 您可以还原页面样式，例如恢复 `.login-page` 的样式
            //     document.querySelector('.login-page').style.height="100%";
            //
            // },
            login() {
                const isPhoneMatch = regexPhone.test(this.phoneNo);
                const isCodeMatch = regexCode.test(this.verifyCode);
                if(!isPhoneMatch){
                    showToast("电话号码格式错误！");
                    return;
                }
                if(!isCodeMatch){
                    showToast("验证码格式错误！");
                    return;
                }
                if(this.phoneNo==oldPhone){
                    showToast("邀请人不能是自己！");
                    return;
                }
                if(!this.checked){
                    showToast("请同意服务条款！");
                    return;
                }
                axios.post('http://47.108.193.146:8090/login/userLoginH5', {
                    // JSON 数据
                    phoneNo: this.phoneNo,
                    verifyCode: this.verifyCode,
                    oldPhone: oldPhone,
                    inviteCode: inviteCode
                }, {
                    headers: {
                        'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                    },
                })
                    .then(response => {
                        // 处理响应数据
                        if(response.data.retCode=="0000"){
                            this.$router.push('invite-success');
                        }else{
                            showToast(response.data.retMsg);
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                    });
            },
            getCode(){
                if(this.timeText=="获取验证码"){
                    // let idfv =""; //urlParams.get('idfv');
                    // const phone = "+61"+this.phoneNo;
                    const isPhoneMatch = regexPhone.test(this.phoneNo);
                    if(!isPhoneMatch){
                        showToast("电话号码格式错误！");
                        return;
                    }
                    this.timeText=60;
                    this.startTimer();
                    axios.post('http://47.108.193.146:8090/login/sendMsgH5', {
                        // JSON 数据
                        phoneNo: this.phoneNo
                    }, {
                        headers: {
                            'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                        },
                    })
                        .then(response => {
                            // 处理响应数据
                            if(response.data.retCode=="0000"){
                                showToast("发送成功！");
                            }else{
                                showToast(response.data.retMsg);
                            }
                        })
                        .catch(error => {
                            // 处理错误
                            console.error(error);
                        });
                }

            },
            startTimer() {
                setInterval(() => {
                    if (this.timeText > 0) {
                        this.timeText--;
                    }else{
                        this.timeText='获取验证码';
                    }
                }, 1000);
            },
            onClickTerms() {
                this.$router.push('terms');
            },
            onClickPrivacy() {
                this.$router.push('privacy');
            },
        }
    };
</script>
