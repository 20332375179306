<template>
    <h3 id="termsTitle" style="text-align: center;padding-left: 3%;padding-right: 3%;">MOBILE APP TERMS AND CONDITIONS OF USE</h3>
    <div class="paragraph" style="padding-left: 3%;padding-right: 3%;">
        <h4>1.About the Application</h4>
        <p style="text-align: left;">(a) Welcome to www.tenhousecleaing.com (Application). The Application Cleaning. (Services)</p>
        <p>(b) The Application is operated by Ten House Pty Ltd (ACN 661907795). Access to and use of the Application, or any of its associated Products or Services, is provided by Ten House Pty Ltd. Please read these terms and conditions (Terms) carefully. By using, browsing and/or reading the Application, this signifies that you have read, understood and agree to be bound by the Terms. lf you do not agree with the Terms, you must cease usage of the Application, or any of Services, immediately.</p>
        <p>(c) Ten House Pty Ltd reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Ten House Pty Ltd updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.</p>
        <h4>2.Acceptance of the Terms House Pty Ltd in the user in</h4>
        <p>(a) You accept the Terms by remaining on the Application. You may also accept the Terms by clicking to accept or agree to the Terms where this option is made available to you by Ten House Pty Ltd in the user interface.</p>
        <h4>3.Registration to use the Services</h4>
        <p>In order to access the Services, you must first register for an account through the Application (Account).</p>
        <span>b) As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including:
            <br/>i. Email address
            <br/>
            ii. Mailing address<br/>
            iii. Telephone number Password<br/>
            iv. Password<br/>
            v. Service Address<br/>
        </span>
        <p>c) You completing the registration process will always be accurate, correct and up to date.</p>
        <p>d) Once you have completed the registration process, you will be a registered member of the Application (Member) and agree to be bound by the Terms.</p>
        <span>You may not use the Services and may not accept the Terms if:
            <br/>i. you are not of legal age to form a binding contract with Ten House PtyLtd; or
            <br/>
            ii. you are a person barred from receiving the Services under the laws of
Australia or other countries including the country in which you are resident or from which you use the Services.
        </span>
        <h4>4. Your obligations as a Member</h4>
        <span>As a Member, you agree to comply with the following:
            <br/>i. you will use the Services only for purposes that are permitted by:<br/>
A. the Terms, and<br/>
B. any applicable law, regulation or generally accepted practices
or guidelines in the relevant jurisdictions;
            <br/>
            ii. you have the sole responsibility for protecting the confidentiality of your
password and/or email address. Use of your password by any other
person may result in the immediate cancellation of the Services;
            <br/>
            iii. any use of your registration information by any other person, or third
parties, is strictly prohibited. You agree to immediately notify Ten House Pty Ltd of any unauthorised use of your password or email address or any breach of security of which you have become aware;
            <br/>
            iv. access and use of the Application is limited, non-transferable and allows for the sole use of the Application by you for the purposes of Ten House Pty Ltd providing the Services;
            <br/>
            v. you will not use the Services or the Application in connection with any commercial endeavours except those that are specifically endorsed or approved by the management of Ten House Pty Ltd;
            <br/>
            vi. you will not use the Services or Application for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Application;
            <br/>
            vii. you agree that commercial advertisements, affiliate links, and other forms of solicitation may be removed from the Application without notice and may result in termination of the Services. Appropriate legal action will be taken by Ten House Pty Ltd for any illegal or unauthorised use of the Application; and
            <br/>
            viii. you acknowledge and agree that any automated use of the Application or its Services is prohibited.
        </span>
        <h4>5.Payment</h4>
        <p>a) All payments made in the course of your use of the Services are made using PAYPAL, CARD PAYMENT, ALIPAY, WECHAT. In using the Application, the Services or when making any payment in relation to your use of the Services, you warrant that you have read, understood and agree to be bound by the PAYPAL, CARD PAYMENT, ALIPAY, WECHAT terms and conditions which are available
            on their website.
        </p>
        <p>b) You acknowledge and agree that where a request for the payment of the Services Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Services Fee.</p>
        <p>c) You agree and acknowledge that Ten House Pty Ltd can vary the Services Fee at any time.</p>
        <h4>6. Refund Policy</h4>
        <p>Ten House Pty Ltd will only provide you with a refund of the Services Fee in the event they are unable to continue to provide the Services or if the manager of Ten House Pty Ltd makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances (Refund)
        </p>
        <h4>7. Copyright and Intellectual Property</h4>
        <p>a) The Application, the Services and all of the related products of Ten House Pty Ltd are subject to copyright. The material on the Application is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Application (including but not limited to text, graphics, logos, button icons, video images, audio clips, Application code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by Ten House Pty Ltd or its contributors.
        </p>
        <span>b) All trademarks, service marks and trade names are owned, registered and/or
licensed by Ten House Pty Ltd, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:
            <br/>i. use the Application pursuant to the Terms:
            <br/>
            ii. copy and store the Application and the material contained in the Application in your device's cache memory; and<br/>
            iii. print pages from the Application for your own personal and non- commercial use.<br/>
            Ten House Pty Ltd does not grant you any other rights whatsoever in relation to the Application or the Services. All other rights are expressly reserved by Ten House Pty Ltd.
        </span>
        <br/>
        <br/>
        <span>c) Ten House Pty Ltd retains all rights, title and interest in and to the Application and all related Services. Nothing you do on or in relation to the Application will transfer any:
            <br/>i. business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or

            <br/>
            ii. a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or<br/>
            iii. iii. a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process to you.
        </span>
        <p>d) You may not, without the prior written permission of Ten House Pty Ltd and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third-party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Application, which are freely available for re-use or are in the public domain.</p>

        <h4>8. Privacy</h4>
        <p>a) Ten House Pty Ltd takes your privacy seriously and any information provided through your use of the Application and/or Services are subject to Ten House Pty Ltd's Privacy Policy, which is available on the Application.
        </p>
        <h4>9. General Disclaimer</h4>
        <p>a) Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</p>
        <span>b) Subject to this clause, and to the extent permitted by law:
            <br/>i. all terms, guarantees, warranties, representations or conditions which are
not expressly stated in the Terms are excluded; and

            <br/>
            ii. Ten House Pty Ltd will not be liable for any special, indirect or
consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.
        </span>
        <span>c) Use of the Application and the Services is at your own risk. Everything on the Application and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of Ten House Pty Ltd make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of Ten House Pty Ltd) referred to on the Application. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:
            <br/>i. failure of performance, error omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration or unauthorised access to records;
            <br/>
            ii. the accuracy, suitability or currency of any information on the Application, the Services, or any of its Services related products (including third party material and advertisements on the Application);
            <br/>
            iii. costs incurred as a result of you using the Application, the Services or any of the products of Ten House Pty Ltd, and
            <br/>
            iv. the Services or operation in respect to links which are provided for your convenience.
        </span>
        <h4>10. Limitation of liability</h4>
        <p>a) Ten House Pty Ltd’s total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.
        </p>
        <p>You expressly understand and agree that Ten House Pty Ltd, its affiliates, employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.
        </p>
        <h4>11. Competitors</h4>
        <p>If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of Ten House Pty Ltd. Competitors are not permitted to use or access any information or content on our Application. If you breach this provision, Ten House Pty Ltd will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach.
        </p>
        <h4>12. Termination of Contract</h4>
        <p>a) The Terms will continue to apply until terminated by either you or by Ten House Pty Ltd as set out below.
        </p>
        <span>b) If you want to terminate the Terms, you may do so by:
            <br/>i. providing Ten House Pty Ltd with 7 days' notice of your intention to terminate; and
            <br/>
            ii. closing your accounts for all of the services which you use, where Ten House Pty Ltd has made this option available to you.
            <br/>
            Your notice should be sent, in writing, to Ten House Pty Ltd via the 'Contact Us' link on our homepage.
        </span>
        <br/>
        <br/>
        <span>c) Ten House Pty Ltd may at any time, terminate the Terms with you if:
            <br/>i. you have breached any provision of the Terms or intend to breach any
provision;
            <br/>
            ii. Ten House Pty Ltd is required to do so by law;
            <br/>
            iii. the provision of the Services to you by Ten House Pty Ltd is: in the opinion of Ten House Pty Ltd, no longer commercially viable.
        </span>
        <h4>13. Indemnity</h4>
        <span>a) You agree to indemnify Ten House Pty Ltd, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:
            <br/>i. all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with your content;
            <br/>
            ii. any direct or indirect consequences of you accessing, using or transacting on the Application or attempts to do so, and/or
            <br/>
            iii. any breach of the Terms.
        </span>
        <h4>14. Dispute Resolution</h4>
        <p>a) Compulsory:
            If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</p>
        <p>b) Notice:
            A party to the Terms claiming a dispute (Dispute) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.</p>
        <span>c) Resolution:
On receipt of that notice (Notice) by that other party, the parties to the Terms (Parties) must:
            <br/>i. Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;
            <br/>
            ii. If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must request that an appropriate mediator be appointed by the If a dispute arises in relation to these terms, a mediation body can be used by the parties to resolve the dispute;
            <br/>
            iii. The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;
            <br/>
            iv. The mediation will be held in Melbourne Sydney, Australia.
        </span>
        <p>d) Confidential:
            All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>
        <p>e) Termination of Mediation:
            If 30 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</p>

        <h4>15. Venue and Jurisdiction</h4>
        <p>a) The Services offered by Ten House Pty Ltd is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Application, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia.</p>
        <h4>16. Governing Law</h4>
        <p>a) The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by. under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</p>

        <h4>17. Independent Legal Advice</h4>
        <p>a) Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties having taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.</p>
        <h4>18. Severance</h4>
        <p>a) If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</p>


    </div>
</template>
<style>
    .paragraph {
        text-align: justify; /* 设置段落对齐方式为两端对齐 */
        text-align-last: left; /* 保持最后一行左对齐 */
        text-justify: inter-word; /* 设置单词间隔自动调整，以实现更好的排版效果 */
    }
</style>
<script>
    export default {
        name:'termsPage',
        data() {
            return {
            };
        },
        mounted() {
            document.title = '用户协议';
        },
    };
</script>
