<template>
    <div id="main" class="login-page"  >
<!--        <img class="background-image" src="../assets/imgDayItemBack.png" alt="imgDayItemBack Image"/>-->
        <van-image  width="320px" height="853px"  fit="cover" :src="require('../assets/dayItemBrowser.png')"   />
<!--        <img class="cleanLogo" src="../assets/dayItemOldTop.png" alt="dayItemOldTop image"/>-->
<!--        <img style="position: absolute;top:68%;z-index: 0;width: 380px;height: 441px;" src="../assets/dayItemOldBottom.png">-->
<!--        <div class="login-form" style="position:absolute;top:46%;">-->
<!--        <div class="login-form" style="margin-top: 8%">-->
<!--            <van-field v-model="phoneNo" label-width="10%" label="+61" @input='inputPhone' placeholder="请输入手机号" style="border-radius: 12px;"/>-->
<!--            <van-button id="receiveBtn"  type="primary" text="免费领取" size="large" block @click="receive" class="custom-button"></van-button>-->
<!--        </div>-->
<!--        <van-image  width="380px" height="441px"  fit="cover" style="margin-top: 10%" :src="require('../assets/dayItemOldBottom.png')" />-->

    </div>
</template>
<!--:style="{ backgroundColor: '#E7F3FF' }"-->
<style scoped>
    body {
        /*overflow: hidden;*/
        background-color: #E7F3FF;
    }

    .login-page {
        background-color: #E7F3FF;
        /*height: 100vh;*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden; /* 隐藏滚动条 */
        /*position: absolute;*/
        top:0;
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .cleanLogo {
        position: absolute;
        top: 0%;
        width: 389px;
        height: 324px;
    }

    .login-form {
        width: 80%;
        max-width: 300px;
        /*margin-bottom: 20vh;*/
    }

    .van-checkbox__label {
        color: white !important; /* 将文字颜色设置为白色 */
    }

    .custom-button {
        background-color: #A7C8FF;
        /*background-image: url('../assets/dayItemOldBtnBack1.png');*/
        background-size: cover;
        border-radius: 16px;
        border: none;
        margin-top: 7%;
        /* 其他自定义样式 */
    }
    .transparent-button.van-button {
        background-color: transparent;
        /*color: #333; !* 设置按钮文本颜色 *!*/
        /*border: 1px solid #333; !* 设置按钮边框样式 *!*/
    }
</style>

<script>
    // import {ref} from 'vue';
    import {Image} from 'vant';
    // import { useRoute } from 'vue-router';

    // const userAgent = navigator.mediaDevices.getUserMedia();
    export default {
        components: {
            [Image.name]: Image
        },
        setup() {

            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        name: "loginPage",
        created() {

//             // 阻止页面下拉滚动
//             document.addEventListener('touchmove', function (event) {
//                 event.preventDefault();
//             }, { passive: false });
//
// // 阻止页面触摸滚动
//             document.addEventListener('touchstart', function (event) {
//                 if (event.touches.length > 1) {
//                     event.preventDefault();
//                 }
//             });

        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.body.style.backgroundColor="#E7F3FF";
            document.title = '在浏览器打开';
            // 监听软键盘收起事件
            // document.addEventListener('focusout', this.handleKeyboardHide);
        },
        beforeUnmount() {
            // 移除事件监听
            // document.removeEventListener('focusout', this.handleKeyboardHide);
        },
        data() {
        },
        methods: {
        }
    };
</script>
