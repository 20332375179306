<template>
    <div class="login-page" @wheel.stop=""  >
<!--        <img class="background-image" src="../assets/imgDayItemBack.png" alt="imgDayItemBack Image"/>-->
<!--        <img class="cleanLogo" src="../assets/dayItemNewTop.png" alt="dayItemNewTop image"/>-->
        <van-image  width="389px" height="324px"  fit="cover" :src="require('../assets/dayItemNewTop.png')"   />

<!--        <img style="position: absolute;top:73%;z-index: 0;width: 380px;height: 441px;" src="../assets/dayItemNewBottom.png">-->
        <div class="login-form" style="margin-top: 8%">
            <van-field v-model="phoneNo" label-width="10%" label="+61" @input='inputPhone' placeholder="请输入手机号" style="border-radius: 12px;"/>
            <van-field
                    v-model="verifyCode"
                    placeholder="请输入验证码" @input="inputPhone"
                    style="border-radius: 12px;margin-top: 15px;"
            >
                <template #button>
                    <label @click="getCode" style="color: black;">{{ timeText }}</label>
                </template>
            </van-field>
            <van-button id="receiveBtn"  type="primary" text="免费领取" size="large" block @click="receive" class="custom-button"></van-button>
        </div>
        <van-image  width="380px" height="441px"  fit="cover" :src="require('../assets/dayItemNewBottom.png')" style="margin-top: 10%;"   />

    </div>
</template>
<!--:style="{ backgroundColor: '#E7F3FF' }"-->
<style scoped>
    body {
        overflow: hidden;
        background-color: #E7F3FF;
    }

    .login-page {
        /*height: 100vh;*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden; /* 隐藏滚动条 */
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .cleanLogo {
        position: absolute;
        top: 0%;
        width: 389px;
        height: 324px;
    }

    .login-form {
        width: 80%;
        max-width: 300px;
        /*margin-bottom: 20vh;*/
    }

    .van-checkbox__label {
        color: white !important; /* 将文字颜色设置为白色 */
    }

    .custom-button {
        background-color: #A7C8FF;
        /*background-image: url('../assets/dayItemOldBtnBack1.png');*/
        background-size: cover;
        border-radius: 16px;
        border: none;
        margin-top: 7%;
        /* 其他自定义样式 */
    }
    .transparent-button.van-button {
        background-color: transparent;
        /*color: #333; !* 设置按钮文本颜色 *!*/
        /*border: 1px solid #333; !* 设置按钮边框样式 *!*/
    }
</style>

<script>
    // import {ref} from 'vue';
    import {Field, CellGroup, Button, CheckboxGroup, Checkbox,showToast,Image} from 'vant';
    import axios from 'axios';
    // import { useRoute } from 'vue-router';
    const regexPhone = /^\d{9,10}$/ ;
    const regexCode = /^\d{6}$/;

    // const userAgent = navigator.mediaDevices.getUserMedia();
    export default {
        components: {
            [Field.name]: Field,
            [Button.name]: Button,
            [CellGroup.name]: CellGroup,
            [CheckboxGroup.name]: CheckboxGroup,
            [Checkbox.name]: Checkbox,
            [Image.name]: Image
        },
        setup() {
            // const route = useRoute();
            // console.log(route.query);
            // return {checked};
        },
        name: "loginPage",
        created() {
//             // 阻止页面下拉滚动
//             document.addEventListener('touchmove', function (event) {
//                 event.preventDefault();
//             }, { passive: false });
//
// // 阻止页面触摸滚动
//             document.addEventListener('touchstart', function (event) {
//                 if (event.touches.length > 1) {
//                     event.preventDefault();
//                 }
//             });

        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.body.style.backgroundColor="#E7F3FF";
            document.title = '新人专享';
            // 监听软键盘收起事件
            // document.addEventListener('focusout', this.handleKeyboardHide);
        },
        beforeUnmount() {
            // 移除事件监听
            // document.removeEventListener('focusout', this.handleKeyboardHide);
        },
        data() {
            return {
                phoneNo: '',
                verifyCode: '',
                timeText: '获取验证码',
                checked:false
            }
        },
        methods: {
            // handleKeyboardHide() {
            //     alert(1111);
            //
            //     // 键盘收起时的处理逻辑
            //     // 您可以还原页面样式，例如恢复 `.login-page` 的样式
            //     document.querySelector('.login-page').style.height="100%";
            //
            // },
            receive() {
                const isPhoneMatch = regexPhone.test(this.phoneNo);
                const isCodeMatch = regexCode.test(this.verifyCode);
                if(!isPhoneMatch){
                    showToast("电话号码格式错误！");
                    return;
                }
                if(!isCodeMatch){
                    showToast("验证码格式错误！");
                    return;
                }
                // axios.post('http://47.108.193.146:8090/login/newUserReceiveH5', {
                axios.post('https://api.tenhousecleaning.com/login/newUserReceiveH5', {
                    // JSON 数据
                    phoneNo: this.phoneNo,
                    verifyCode: this.verifyCode
                }, {
                    headers: {
                        'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                    },
                })
                    .then(response => {
                        // 处理响应数据
                        if(response.data.retCode=="0000"){
                            this.$router.push('day-item-success');
                        }else{
                            showToast(response.data.retMsg);
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        console.error(error);
                    });
            },
            getCode(){
                if(this.timeText=="获取验证码"){
                    // let idfv =""; //urlParams.get('idfv');
                    // const phone = "+61"+this.phoneNo;
                    const isPhoneMatch = regexPhone.test(this.phoneNo);
                    if(!isPhoneMatch){
                        showToast("电话号码格式错误！");
                        return;
                    }
                    this.timeText=60;
                    this.startTimer();
                    axios.post('https://api.tenhousecleaning.com/login/sendMsgH5', {
                        // JSON 数据
                        phoneNo: this.phoneNo
                    }, {
                        headers: {
                            'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                        },
                    })
                        .then(response => {
                            // 处理响应数据
                            if(response.data.retCode=="0000"){
                                showToast("发送成功！");
                            }else{
                                showToast(response.data.retMsg);
                            }
                        })
                        .catch(error => {
                            // 处理错误
                            console.error(error);
                        });
                }

            },
            startTimer() {
                setInterval(() => {
                    if (this.timeText > 0) {
                        this.timeText--;
                    }else{
                        this.timeText='获取验证码';
                    }
                }, 1000);
            },
            inputPhone(){
                const isPhoneMatch = regexPhone.test(this.phoneNo);
                const isCodeMatch = regexCode.test(this.verifyCode);
                if(isPhoneMatch&&isCodeMatch){
                    document.getElementById('receiveBtn').style.backgroundColor="#2475FF";
                    return;
                }else{
                    document.getElementById('receiveBtn').style.backgroundColor="#A7C8FF";
                }
            }
        }
    };
</script>
