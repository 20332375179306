<template>
    <div class="login-page" @wheel.stop="">
<!--        <van-button type="primary" @click="selectAll">全选</van-button>&nbsp;-->
<!--        <van-button type="primary" @click="toggleSelect">取消选择</van-button>-->
      <div v-for="group in checkboxGroups" :key="group.id">
          <h3>{{ group.name }}</h3>
        <van-checkbox-group v-model="group.selectedValues">
            <van-cell-group>
                <van-cell class="custom-cell" v-for="option in group.options" clickable :key="option.value" :title="`${option.label}`">
                    <template #right-icon>
                    <van-checkbox :name="group.name+option.value" ref="checkboxes"/>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-checkbox-group>
      </div>
        <van-cell-group>
            <van-field
                    v-model="note"
                    label="备注"
                    type="textarea"
                    placeholder="请输入备注（最多200汉字）"
                    :maxlength="400"
                    rows="8"
                    autosize
            />
        </van-cell-group>
        <van-button type="primary" size="large" @click="submit">提 交</van-button>
    </div>
</template>
<!--<style>-->
<!--    .custom-cell .van-cell__title {-->
<!--        max-width: calc(100% - 50px); /* Adjust the value as needed */-->
<!--        white-space: nowrap;-->
<!--        overflow: hidden;-->
<!--        text-overflow: ellipsis;-->
<!--    }-->
<!--</style>-->
<style scoped>
    .custom-cell .van-cell__title {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {Checkbox, CheckboxGroup, Cell, CellGroup, Field, Button, showToast} from 'vant';
    import axios from "axios";
    const urlParams = new URLSearchParams(window.location.search);
    const orderNo = urlParams.get('orderNo');
    const phoneNo = urlParams.get('phoneNo');
    export default {
        components: {
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Field.name]: Field,
            [Button.name]: Button
        },
        mounted() {
            document.title = '员工检查';
        },
        methods:{
            // selectAll() {
            //     this.checkboxGroups.forEach(group => {
            //         group.selectedValues = group.options.map(option => group.name+option.value);
            //     });
            // },
            // toggleSelect() {
            //     this.checkboxGroups.forEach(group => {
            //         group.selectedValues = [
            //             // ...group.selectedValues,
            //             // ...unselectedOptions,
            //         ];
            //     });
            // },
            generateCheckboxName(group,option){
                return `${group.name}-${option.value}`;
            },
            submit(){
                // 检查每个组的选中状态，确保至少一个选项被选中
                const hasAtLeastOneSelected = this.checkboxGroups.some(group => group.selectedValues.length > 0);

                if (!hasAtLeastOneSelected) {
                    showToast("至少选择一个！");
                    return;
                }
                let ExamConvention={},ExamBalcony={},ExamBathroom={},ExamBedroom={},ExamKicthen={},ExamLaundry={},ExamLivingroom={};
                this.checkboxGroups.forEach(group => {
                    if(group.id=="1") {
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name + option.value);
                            const valueToSend = selected ? 1 : 0;
                            if (option.value == "one") {
                                ExamConvention.one = valueToSend;
                            } else if (option.value == "two") {
                                ExamConvention.two = valueToSend;
                            } else if (option.value == "three") {
                                ExamConvention.three = valueToSend;
                            } else if (option.value == "four") {
                                ExamConvention.four = valueToSend;
                            } else if (option.value == "five") {
                                ExamConvention.five = valueToSend;
                            } else if (option.value == "six") {
                                ExamConvention.six = valueToSend;
                            } else if (option.value == "seven") {
                                ExamConvention.seven = valueToSend;
                            }
                        });
                        ExamConvention.note = this.note;
                    }else if(group.id=="2"){
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name + option.value);
                            const valueToSend = selected ? 1 : 0;
                            if (option.value == "one") {
                                ExamBedroom.one = valueToSend;
                            } else if (option.value == "two") {
                                ExamBedroom.two = valueToSend;
                            } else if (option.value == "three") {
                                ExamBedroom.three = valueToSend;
                            } else if (option.value == "four") {
                                ExamBedroom.four = valueToSend;
                            } else if (option.value == "five") {
                                ExamBedroom.five = valueToSend;
                            } else if (option.value == "six") {
                                ExamBedroom.six = valueToSend;
                            }
                        });
                    }else if(group.id=="3"){
                        group.options.map(option => {

                            const selected = group.selectedValues.includes(group.name+option.value);
                            const valueToSend = selected ? 1 : 0;
                            if(option.value=="one"){
                                ExamKicthen.one=valueToSend;
                            }else if(option.value=="two"){
                                ExamKicthen.two=valueToSend;
                            }else if(option.value=="three"){
                                ExamKicthen.three=valueToSend;
                            }else if(option.value=="four"){
                                ExamKicthen.four=valueToSend;
                            }else if(option.value=="five"){
                                ExamKicthen.five=valueToSend;
                            }else if(option.value=="six"){
                                ExamKicthen.six=valueToSend;
                            }else if(option.value=="seven"){
                                ExamKicthen.seven=valueToSend;
                            }else if(option.value=="eight"){
                                ExamKicthen.eight=valueToSend;
                            }else if(option.value=="nine"){
                                ExamKicthen.nine=valueToSend;
                            }else if(option.value=="ten"){
                                ExamKicthen.ten=valueToSend;
                            }else if(option.value=="eleven"){
                                ExamKicthen.eleven=valueToSend;
                            }else if(option.value=="twelve"){
                                ExamKicthen.twelve=valueToSend;
                            }else if(option.value=="thirteen"){
                                ExamKicthen.thirteen=valueToSend;
                            }else if(option.value=="fourteen"){
                                ExamKicthen.fourteen=valueToSend;
                            }else if(option.value=="fifteen"){
                                ExamKicthen.fifteen=valueToSend;
                            }
                        });
                    }else if(group.id=="4"){
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name+option.value);
                            const valueToSend = selected ? 1 : 0;
                            if(option.value=="one"){
                                ExamLivingroom.one=valueToSend;
                            }else if(option.value=="two"){
                                ExamLivingroom.two=valueToSend;
                            }else if(option.value=="three"){
                                ExamLivingroom.three=valueToSend;
                            }else if(option.value=="four"){
                                ExamLivingroom.four=valueToSend;
                            }else if(option.value=="five"){
                                ExamLivingroom.five=valueToSend;
                            }
                        });
                    }else if(group.id=="5"){
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name+option.value);
                            const valueToSend = selected ? 1 : 0;
                            if(option.value=="one"){
                                ExamBalcony.one=valueToSend;
                            }else if(option.value=="two"){
                                ExamBalcony.two=valueToSend;
                            }else if(option.value=="three"){
                                ExamBalcony.three=valueToSend;
                            }else if(option.value=="four"){
                                ExamBalcony.four=valueToSend;
                            }
                        });
                    }else if(group.id=="6"){
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name+option.value);
                            const valueToSend = selected ? 1 : 0;
                            if(option.value=="one"){
                                ExamBathroom.one=valueToSend;
                            }else if(option.value=="two"){
                                ExamBathroom.two=valueToSend;
                            }else if(option.value=="three"){
                                ExamBathroom.three=valueToSend;
                            }else if(option.value=="four"){
                                ExamBathroom.four=valueToSend;
                            }else if(option.value=="five"){
                                ExamBathroom.five=valueToSend;
                            }else if(option.value=="six"){
                                ExamBathroom.six=valueToSend;
                            }else if(option.value=="seven"){
                                ExamBathroom.seven=valueToSend;
                            }else if(option.value=="eight"){
                                ExamBathroom.eight=valueToSend;
                            }else if(option.value=="nine"){
                                ExamBathroom.nine=valueToSend;
                            }
                        });
                    }else if(group.id=="7"){
                        group.options.map(option => {
                            const selected = group.selectedValues.includes(group.name+option.value);
                            const valueToSend = selected ? 1 : 0;
                            if(option.value=="one"){
                                ExamLaundry.one=valueToSend;
                            }else if(option.value=="two"){
                                ExamLaundry.two=valueToSend;
                            }else if(option.value=="three"){
                                ExamLaundry.three=valueToSend;
                            }else if(option.value=="four"){
                                ExamLaundry.four=valueToSend;
                            }else if(option.value=="five"){
                                ExamLaundry.five=valueToSend;
                            }else if(option.value=="six"){
                                ExamLaundry.six=valueToSend;
                            }else if(option.value=="seven"){
                                ExamLaundry.seven=valueToSend;
                            }else if(option.value=="eight"){
                                ExamLaundry.eight=valueToSend;
                            }
                        });
                    }
                });

                // axios.post('http://47.108.193.146:8090/h5/empExam', {
                axios.post('https://api.tenhousecleaning.com/h5/empExam', {
                // axios.post('https://api.tenhousecleaning.com/login/sendMsgH5', {
                    // JSON 数据
                    phoneNo: phoneNo,
                    orderNo: orderNo,
                    examConvention: ExamConvention,
                    examBedroom: ExamBedroom,
                    examKicthen: ExamKicthen,
                    examLivingroom: ExamLivingroom,
                    examBalcony: ExamBalcony,
                    examBathroom: ExamBathroom,
                    examLaundry: ExamLaundry,
                }, {
                    headers: {
                        'Content-Type': 'application/json', // 设置请求头为 JSON 类型
                    },
                })
                    .then(response => {
                        console.error(JSON.stringify(response));
                        // 处理响应数据
                        if(response.data.retCode=="0000"){
                            showToast("保存成功！");
                        }else{
                            showToast(response.data.retMsg);
                        }
                    })
                    .catch(error => {
                        // 处理错误
                        showToast("保存失败！");
                        console.error(error);
                    });
            }
        },
        data() {
            return {
                note:undefined,
                list: ['a', 'b', 'c'],
                result: ['a', 'b'],
                checkboxGroups: [
                    {
                        id: 1,
                        name: '常规清洁项',
                        options: [
                            {label: '除尘所有表面，包括家具、镜子、相框、装饰品', value: 'one'},
                            {label: '用吸尘器或清扫所有地面，包括地板、地毯、瓷砖', value: 'two'},
                            {label: '拖把所有地面，包括地板、地毯、瓷砖', value: 'three'},
                            {label: '清空所有垃圾桶并更换垃圾袋', value: 'four'},
                            {label: '清洁所有窗台', value: 'five'},
                            {label: '擦拭所有容易触及的灯具和开关', value: 'six'},
                            {label: '清除所有蜘蛛网', value: 'seven'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 2,
                        name: '卧室',
                        options: [
                            {label: '替换所有床上用品', value: 'one'},
                            {label: '擦拭/除尘床头柜和灯', value: 'two'},
                            {label: '擦拭/除尘所有窗台', value: 'three'},
                            {label: '清洁所有镜子', value: 'four'},
                            {label: '检查所有橱柜和抽屉（移走之前客人用过的物品并整理原本房间内物品）', value: 'five'},
                            {label: '卷起百叶窗', value: 'six'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 3,
                        name: '厨房',
                        options: [
                            {label: '检查水槽和洗碗机中是否有脏盘子', value: 'one'},
                            {label: '把洗碗机中的盘子取出，并放置橱柜内', value: 'two'},
                            {label: '清空并擦拭冰箱内部/外部', value: 'three'},
                            {label: '清空并擦拭微波炉内部/外部', value: 'four'},
                            {label: '擦拭烤箱并清楚面包屑', value: 'five'},
                            {label: '清洁水槽，并擦干', value: 'six'},
                            {label: '擦干水龙头', value: 'seven'},
                            {label: '更换垃圾袋', value: 'eight'},
                            {label: '检查所有橱柜和抽屉（移走之前客人用过的物品并整理原本房间内物品）', value: 'nine'},
                            {label: '清洁水池背部溅水', value: 'ten'},
                            {label: '擦掉柜子上的指印', value: 'eleven'},
                            {label: '擦拭台面和椅子', value: 'twelve'},
                            {label: '补充洗碗机块', value: 'thirteen'},
                            {label: '补充洗洁精', value: 'fourteen'},
                            {label: '补充垃圾袋', value: 'fifteen'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 4,
                        name: '客厅',
                        options: [
                            {label: '擦拭/除尘所有表面（咖啡桌、电视桌、窗台）', value: 'one'},
                            {label: '清洁镜子', value: 'two'},
                            {label: '吸尘布艺沙发、擦拭皮沙发', value: 'three'},
                            {label: '确保一切看起来规整', value: 'four'},
                            {label: '清洁台面', value: 'five'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 5,
                        name: '阳台',
                        options: [
                            {label: '擦拭桌子', value: 'one'},
                            {label: '扔垃圾并清理烟头', value: 'two'},
                            {label: '吸尘/扫地', value: 'three'},
                            {label: '确保一切看起来规整', value: 'four'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 6,
                        name: '浴室',
                        options: [
                            {label: '清洁马桶', value: 'one'},
                            {label: '擦拭淋浴间玻璃', value: 'two'},
                            {label: '擦拭淋浴间墙面瓷砖', value: 'three'},
                            {label: '确保淋浴间下水道没有毛发', value: 'four'},
                            {label: '清洁镜子', value: 'five'},
                            {label: '清洁梳妆台、水槽、浴缸（确保没有毛发）', value: 'six'},
                            {label: '更换垃圾桶的垃圾袋', value: 'seven'},
                            {label: '补充卫生纸', value: 'eight'},
                            {label: '补充沐浴露、洗发水、洗手液', value: 'nine'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    {
                        id: 7,
                        name: '洗衣房',
                        options: [
                            {label: '擦拭洗衣机门内测', value: 'one'},
                            {label: '擦拭洗衣机', value: 'two'},
                            {label: '清理烘干机的绒毛盒', value: 'three'},
                            {label: '擦拭烘干机', value: 'four'},
                            {label: '清洁台面', value: 'five'},
                            {label: '擦拭橱柜和抽屉', value: 'six'},
                            {label: '清洁水槽和水龙头', value: 'seven'},
                            {label: '补充洗衣球', value: 'eight'}
                        ],
                        selectedValues: [] // To store selected values
                    },
                    // Add more groups here
                ]
            };
        }
    };
</script>
