<template>
    <div class="login-page" @wheel.stop="">
        <img src="../../assets/invite_bg.png" class="invite-bg">
        <van-button id="receiveBtn" @click="invite"
                    class="invite-app-button"></van-button>
    </div>
</template>
<style>
    .login-page {
        position: relative; /* 设置父容器为相对定位 */
        /*width: 100%; !* 确保容器占据整个宽度 *!*/
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .invite-bg {
        /*z-index: -1;*/
        /*max-width: 100%;*/
        /*height: 100%;*/
        /*object-fit: cover; !* 根据容器大小调整图片裁剪方式 *!*/
        /*object-fit: contain;*/
        /*width: 750px;*/
        /*height: 1980px;*/
        max-width: 100%;
        height: 100%;
    }
    .invite-app-button {
        width: 87%;
        position: absolute; /* 绝对定位，可以放置在任意位置 */
        bottom: 26.8%; /* 从顶部的位置，可以根据需要调整 */
        left: 6%; /* 从左侧的位置，可以根据需要调整 */
        background-image: url("../../assets/invite_new_friend.png");
        background-size: cover;
        background-position: center;
        padding: 7%; /* 按钮内边距，可以根据需要调整 */
        border: none; /* 移除按钮边框 */
        border-radius: 100px; /* 圆角按钮 */
        cursor: pointer; /* 鼠标指针样式 */
    }
</style>
<script>
    import {Button} from 'vant';

    export default {
        components: {
            [Button.name]: Button
        },
        data(){
            return {
                params:{'title':'拾舍清洁-直营酒店式清洁服务预订平台','content':'专业的一站式清洁服务企业','h5Link':'http://www.tenhousecleaning.com/clean-h5/invite-new'}
            }
        },
        created() {
            window.mobileOCjs={
                openNewWeb:this.openNewWeb
            }
        },
        mounted() {
            document.body.style.overflow = 'hidden';
            document.title = '邀请';
        },
        methods:{
            openNewWeb(url){
                window.webkit.messageHandlers.jsMethod.postMessage(url);
            },
            invite(){
                window.mobileOCjs.openNewWeb(JSON.stringify(this.params));
            }
        }
    }
</script>