<template>
    <div class="login-page" @wheel.stop="">
        <img class="long-image" src="../../assets/ratingRules.jpg">
    </div>
</template>
<style>
    /* 在样式中可以进一步自定义样式 */
    .long-image {
        max-width: 100%;
        height: auto;
    }
</style>
<script>
    export default {
        data(){
            return{
                imageUrl: '../../assets/ratingRules.jpg',
            }
        }
    }
</script>



